import React, { useState, useEffect } from "react";
import "./selectService.css";
import "../serviceRequestDetails/serviceRequestDetails.jsx";
import { LabelText, ButtonInput } from "@emerson/dynamic-ui-public";
import FieldServiceIco from "../../assets/FieldService.png";
import ReturnaProductIco from "../../assets/ReturnProduct.png";
import useOnScreen from "../../customHooks/UseOnScreen.js";

const SelectFieldService = (props) => {
  const {
    activeInStep,
    setActiveInStep,
    serviceTypeName,
    setServiceTypeName,
    serviceName,
    setServiceName,
    userSiteList,
    setSelectedSite,
    selectedSite,
    setPrefSerDate,
    setAddDetails,
    setEndUser,
    endUser,
    setEndUserSiteAddress,
    setStepperDisable,
    setStepMemoize,
    stepMemoize,
    setSysInfoDetails,
    endUserSiteAddress,
    setSessionTrigger,
    sessionTrigger
  } = props;

  useEffect(() => {
    console.log("userSiteList:", userSiteList);
  }, [userSiteList]);

  useEffect(() => {
    sessionStorage.removeItem("serviceTypeName");
    setStepperDisable(true)
    if (stepMemoize === 3) {
      setServiceName("");
      setActiveInStep(2);
    }else{
    setStepMemoize(1);
    }
  }, []);
   // visibility check
   const handleVisibilityChange = () => {
    if (stepMemoize === 3) {
      setServiceName("");
      setActiveInStep(2);
    }
  };
  const ref = useOnScreen(handleVisibilityChange);
  //visibilty check

  useEffect(() => {
    // serviceName === "" ? setStepperDisable(true) : setStepperDisable(false);
  }, [serviceName]);
  return (
    <div className="ServiceRequestBox">
      <br />
      <br />
      <LabelText
            text={"You can change your selection by choosing below services"}
            textClass="ddl-text--h3 label-text-gap"
          />
          <LabelText
            text={`Your earlier selection was ${serviceTypeName ==="Request Field Service"? "Field Service":serviceTypeName}`}
            textClass="ddl-text"
          />
          <div className="serviceType">
            <div
              onClick={() => {
                sessionStorage.setItem(
                  "serviceTypeName",
                  "Request Field Service"
                );
                //setSessionTrigger(!sessionTrigger);
                setServiceTypeName("Request Field Service");
                setActiveInStep(2)
              }}
            >
              <img src={FieldServiceIco} alt="Icon" />
              Field Service
            </div>
            <div
              onClick={() => {
                sessionStorage.setItem("serviceTypeName", "Return A Product");
               // setSessionTrigger(!sessionTrigger);
                setServiceTypeName("Return A Product");
                setActiveInStep(2)
              }}
            >
              <img src={ReturnaProductIco} alt="Icon" />
              Return a Product
            </div>
          </div>
        </div>
  );
};

export default SelectFieldService;
